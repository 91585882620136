
import { mapState } from 'vuex'

export default {
  name: 'NowNavAccount',
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapState({
      companyUnitIndex: state => state.companyUnit.index.items
    }),
    mustLoginToCompanyUnit () {
      return !this.$auth.user.company_unit_id && this.companyUnitIndex.length && !this.showMenu
    }
  }
}
