import roleTypes from '@/constants/roleTypes'

/**
 * Check if route requires permission to access, if so, check for user role and permission.
 *
 * @param route
 * @param redirect
 * @param $auth
 */
export default function ({ route, redirect, $auth }) {
  // const permissionRequired = !!route.meta.find(meta => meta.role || meta.permission)
  if (isRestricted(route)) {
    const user = $auth.user

    const requiredRoles = findRequiredRouteRoles(route)
    const requiredPermissions = findRequiredRoutePermissions(route)

    const hasRole = checkRole(user, requiredRoles)
    const hasPermission = checkPermission(user, requiredPermissions)

    if (!hasRole && !hasPermission) {
      redirect('/')
    }
  }
}

/**
 * Check if route is restricted by role or permission
 *
 * @param route
 * @returns {boolean}
 */
function isRestricted (route) {
  return !!route.meta.find(meta => meta.role || meta.permission)
}

/**
 * Extract required roles from route object
 *
 * @param route
 * @returns {*|null}
 */
function findRequiredRouteRoles (route) {
  const roleMeta = route.meta.find(meta => meta.role)
  if (roleMeta) {
    return roleMeta?.role?.split('|') || null
  }
  return null
}

/**
 * Extract required permissions from route object
 *
 * @param route
 * @returns {*|null}
 */
function findRequiredRoutePermissions (route) {
  const permissionMeta = route.meta.find(meta => meta.permission)
  if (permissionMeta) {
    return permissionMeta?.permission?.split('|') || null
  }
  return null
}

/**
 * Check user role access
 *
 * @returns {boolean}
 * @param user
 * @param requiredRoles
 */
export function checkRole (user, requiredRoles) {
  // If no user return false
  if (!user) { return false }
  // If user is admin return true
  if (user?.role === roleTypes.ADMIN) { return true }
  // Else check if user has needed role
  return requiredRoles.includes(user.role)
}

/**
 * Check user permission access
 *
 * @returns {*|boolean}
 * @param user
 * @param requiredPermissions
 */
export function checkPermission (user, requiredPermissions) {
  // If no user return false
  if (!user) { return false }
  // If user is admin return true
  if (user?.role === roleTypes.ADMIN) { return true }
  // Else check if user has needed permission
  const userPermissions = user.permissions.map(permission => permission.name)
  return hasOverlap(userPermissions, requiredPermissions)
}

/**
 * Check if arrays have overlap
 *
 * @returns {*}
 * @param userPermissions
 * @param requiredPermissions
 */
function hasOverlap (userPermissions, requiredPermissions) {
  const userPermissionsSet = new Set(userPermissions)
  return requiredPermissions.some(item => userPermissionsSet.has(item))
}
