import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getRiskDefinitionIndex ({ commit }) {
    try {
      commit(C.RISK_DEFINITION_INDEX_PENDING)
      const res = await this.$axios.get('risk-definitions')
      if (res.status === 200 || res.status === 201) {
        commit(C.RISK_DEFINITION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.RISK_DEFINITION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_DEFINITION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateRiskDefinition ({ commit }, payload) {
    try {
      commit(C.RISK_DEFINITION_UPDATE_PENDING)
      const res = await this.$axios.put(`risk-definitions/${payload.id}`, payload)
      commit(C.RISK_DEFINITION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_DEFINITION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteRiskDefinition ({ commit }, payload) {
    try {
      commit(C.RISK_DEFINITION_DELETE_PENDING)
      const res = await this.$axios.delete(`risk-definitions/${payload.id}`)
      commit(C.RISK_DEFINITION_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_DEFINITION_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRiskDefinitionStore ({ commit }) {
    commit(C.RISK_DEFINITION_RESET_STORE)
  }
}
