import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getContactIndex ({ commit }, payload) {
    try {
      commit(C.CONTACT_INDEX_PENDING)
      const res = await this.$axios.get('contacts', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.CONTACT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.CONTACT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.CONTACT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getContact ({ commit }, payload) {
    try {
      commit(C.CONTACT_SHOW_PENDING)
      const res = await this.$axios.get(`contacts/${payload}`)
      commit(C.CONTACT_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONTACT_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createContact ({ commit }, payload) {
    try {
      commit(C.CONTACT_CREATE_PENDING)
      const res = await this.$axios.post('contacts', payload)
      commit(C.CONTACT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONTACT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateContact ({ commit }, payload) {
    try {
      commit(C.CONTACT_UPDATE_PENDING)
      const res = await this.$axios.put(`contacts/${payload.id}`, payload)
      commit(C.CONTACT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONTACT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteContact ({ commit }, payload) {
    try {
      commit(C.CONTACT_DELETE_PENDING)
      const res = await this.$axios.delete(`contacts/${payload.id}`)
      commit(C.CONTACT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.CONTACT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetContactStore ({ commit }) {
    commit(C.CONTACT_RESET_STORE)
  }
}
