import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getTaskFileIndex ({ commit }, payload) {
    try {
      commit(C.TASK_FILE_INDEX_PENDING)
      const res = await this.$axios.get(`task-files/${payload}`)
      if (res.status === 200) {
        commit(C.TASK_FILE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.TASK_FILE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.TASK_FILE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createTaskFile ({ commit }, payload) {
    const formData = new FormData()
    formData.append('file', payload.file)
    formData.append('task_id', payload.id)
    try {
      commit(C.TASK_FILE_CREATE_PENDING)
      const res = await this.$axios.post('task-files', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.TASK_FILE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_FILE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteTaskFile ({ commit }, payload) {
    try {
      commit(C.TASK_FILE_DELETE_PENDING)
      const res = await this.$axios.delete(`task-files/${payload}`)
      commit(C.TASK_FILE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.TASK_FILE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetTaskFileStore ({ commit }) {
    commit(C.TASK_FILE_RESET_STORE)
  }
}
