import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSupplierTagIndex ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TAG_INDEX_PENDING)
      const res = await this.$axios.get('supplier-tags', { params: payload })
      if (res.status === 200) {
        commit(C.SUPPLIER_TAG_INDEX_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TAG_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TAG_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createSupplierTag ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TAG_CREATE_PENDING)
      const res = await this.$axios.post('supplier-tags', payload)
      commit(C.SUPPLIER_TAG_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TAG_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetSupplierTagStore ({ commit }) {
    commit(C.SUPPLIER_TAG_RESET_STORE)
  }
}
