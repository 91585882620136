// COMPANY UNIT CONSTANTS
export const COMPANY_UNIT_INDEX_PENDING = 'COMPANY_UNIT_INDEX_PENDING'
export const COMPANY_UNIT_INDEX_SUCCESS = 'COMPANY_UNIT_INDEX_SUCCESS'
export const COMPANY_UNIT_INDEX_FAILURE = 'COMPANY_UNIT_INDEX_FAILURE'
export const COMPANY_UNIT_INDEX_NO_CONTENT = 'COMPANY_UNIT_INDEX_NO_CONTENT'

export const COMPANY_UNIT_CREATE_PENDING = 'COMPANY_UNIT_CREATE_PENDING'
export const COMPANY_UNIT_CREATE_SUCCESS = 'COMPANY_UNIT_CREATE_SUCCESS'
export const COMPANY_UNIT_CREATE_FAILURE = 'COMPANY_UNIT_CREATE_FAILURE'

export const COMPANY_UNIT_UPDATE_PENDING = 'COMPANY_UNIT_UPDATE_PENDING'
export const COMPANY_UNIT_UPDATE_SUCCESS = 'COMPANY_UNIT_UPDATE_SUCCESS'
export const COMPANY_UNIT_UPDATE_FAILURE = 'COMPANY_UNIT_UPDATE_FAILURE'

export const COMPANY_UNIT_DELETE_PENDING = 'COMPANY_UNIT_DELETE_PENDING'
export const COMPANY_UNIT_DELETE_SUCCESS = 'COMPANY_UNIT_DELETE_SUCCESS'
export const COMPANY_UNIT_DELETE_FAILURE = 'COMPANY_UNIT_DELETE_FAILURE'

export const COMPANY_UNIT_RESET_STORE = 'COMPANY_UNIT_RESET_STORE'
