// RISK SCENARIO CONSTANTS
export const RISK_SCENARIO_CREATE_PENDING = 'RISK_SCENARIO_CREATE_PENDING'
export const RISK_SCENARIO_CREATE_SUCCESS = 'RISK_SCENARIO_CREATE_SUCCESS'
export const RISK_SCENARIO_CREATE_FAILURE = 'RISK_SCENARIO_CREATE_FAILURE'

export const RISK_SCENARIO_UPDATE_PENDING = 'RISK_SCENARIO_UPDATE_PENDING'
export const RISK_SCENARIO_UPDATE_SUCCESS = 'RISK_SCENARIO_UPDATE_SUCCESS'
export const RISK_SCENARIO_UPDATE_FAILURE = 'RISK_SCENARIO_UPDATE_FAILURE'

export const RISK_SCENARIO_DELETE_PENDING = 'RISK_SCENARIO_DELETE_PENDING'
export const RISK_SCENARIO_DELETE_SUCCESS = 'RISK_SCENARIO_DELETE_SUCCESS'
export const RISK_SCENARIO_DELETE_FAILURE = 'RISK_SCENARIO_DELETE_FAILURE'

export const RISK_SCENARIO_RESET_STORE = 'RISK_SCENARIO_RESET_STORE'
