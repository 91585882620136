
import { mapActions, mapState } from 'vuex'
import breakpoints from '../constants/breakpoints'
import { checkRole, checkPermission } from '@/middleware/permission'
import roleTypes from '@/constants/roleTypes'
import permissionTypes from '@/constants/permissionTypes'

export default {
  name: 'NowSidebar',
  data () {
    return {
      links: [
        { label: 'home', to: '/', icon: 'building-library', role: null, permission: null },
        { label: 'report', to: '/report', icon: 'document-text', role: null, permission: null },
        { label: 'myTask', to: '/task', icon: 'clipboard-document-check', role: null, permission: null },
        { label: 'groupTask', to: '/task/group', icon: 'clipboard-document', role: null, permission: null },
        { label: 'deviation', to: '/deviation', icon: 'shield-exclamation', role: null, permission: null },
        { label: 'suggestion', to: '/suggestion', icon: 'light-bulb', role: null, permission: null },
        { label: 'action', to: '/action', icon: 'clipboard-document-list', role: [roleTypes.ADMIN, roleTypes.WRITER], permission: null },
        { label: 'suppliers', to: '/supplier', icon: 'truck', role: null, permission: null },
        // TODO: Enable when audit is ready
        // { label: 'audit', to: '/audit', icon: 'bell', role: 'admin|writer' },
        { label: 'unit', to: '/unit', icon: 'rectangle-group', role: null, permission: null },
        { label: 'risk', to: '/risk', icon: 'fire', role: null, permission: [permissionTypes.RISK_CAN_EDIT] },
        { label: 'it', to: '/it', icon: 'computer-desktop', role: null, permission: [permissionTypes.IT_CAN_EDIT] },
        { label: 'archive', to: '/archive', icon: 'folder', role: null, permission: null }
      ],
      breakpoints
    }
  },
  computed: {
    ...mapState({
      sidebarCollapsed: state => state.app.sidebarCollapsed,
      report: state => state.report.id.item,
      reportSections: state => state.report.sections.items,
      sidebarToggled: state => state.app.sidebarToggled
    }),
    organizationLogo () {
      return this.$auth?.user?.organization?.logo
    },
    userLinks () {
      const user = this.$auth.user
      const userRole = this.$auth.user.role
      return this.links.filter((link) => {
        // Filter group task page
        if (link.label === 'groupTask') {
          return userRole === roleTypes.ADMIN || userRole === roleTypes.SYSTEM || this.$auth.user.is_ceo || this.$auth.user.group_leader.length
        } else if (link.role || link.permission) {
          return (link.role ? checkRole(user, link.role) : false) || (link.permission ? checkPermission(user, link.permission) : false)
        }
        return true
      })
    }
  },
  watch: {
    '$route' () {
      this.toggleSidebar(false)
    }
  },
  mounted () {
    this.collapseSidebar(window.innerWidth >= breakpoints.LG)
    window.addEventListener('resize', this.resizeEventHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeEventHandler)
  },
  methods: {
    ...mapActions({
      collapseSidebar: 'app/collapseSidebar',
      toggleSidebar: 'app/toggleSidebar'
    }),
    handleCollapseSidebar () {
      this.collapseSidebar(!this.sidebarCollapsed)
    },
    handleToggleSidebar () {
      this.toggleSidebar(!this.sidebarToggled)
    },
    resizeEventHandler () {
      this.collapseSidebar(window.innerWidth >= breakpoints.LG)
    },
    activeLink (link) {
      const pathElements = this.$route.path.split('/').filter(route => !!route)
      const linkElements = link.to.split('/').filter(route => !!route)
      return JSON.stringify(pathElements) === JSON.stringify(linkElements)
    }
  }
}
