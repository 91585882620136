import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeIndex ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_INDEX_PENDING)
      const res = await this.$axios.get('supplier-types', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TYPE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TYPE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TYPE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeList ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_LIST_PENDING)
      const res = await this.$axios.get('supplier-types/list')
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TYPE_LIST_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TYPE_LIST_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TYPE_LIST_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSupplierType ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_SHOW_PENDING)
      const res = await this.$axios.get(`supplier-types/${payload}`)
      commit(C.SUPPLIER_TYPE_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createSupplierType ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CREATE_PENDING)
      const res = await this.$axios.post('supplier-types', payload)
      commit(C.SUPPLIER_TYPE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateSupplierType ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_UPDATE_PENDING)
      const res = await this.$axios.put(`supplier-types/${payload.id}`, payload)
      commit(C.SUPPLIER_TYPE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSupplierType ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_DELETE_PENDING)
      const res = await this.$axios.delete(`supplier-types/${payload.id}`)
      commit(C.SUPPLIER_TYPE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSupplierTypeAndTasks ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_DELETE_PENDING)
      const res = await this.$axios.delete(`supplier-types/${payload.id}/tasks`)
      commit(C.SUPPLIER_TYPE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeTasks ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_TASKS_PENDING)
      const res = await this.$axios.get(`supplier-types/${payload.id}/tasks`, { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TYPE_TASKS_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TYPE_TASKS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TYPE_TASKS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeTasksCount ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_TASKS_COUNT_PENDING)
      const res = await this.$axios.get(`supplier-types/${payload.id}/tasks/count`, { params: payload })
      commit(C.SUPPLIER_TYPE_TASKS_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_TASKS_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetSupplierTypeStore ({ commit }) {
    commit(C.SUPPLIER_TYPE_RESET_STORE)
  }
}
