import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   */
  setOrganization ({ commit }, payload) {
    commit(C.ORGANIZATION_SET, payload)
  },

  /**
   * @param commit
   * @param $config
   * @returns {Promise<void>}
   */
  async getOrganizationIndexByUser ({ commit }) {
    try {
      commit(C.ORGANIZATION_INDEX_PENDING)
      const res = await this.$axios.get('organizations')
      if (res.status === 200 && res.data) {
        commit(C.ORGANIZATION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.ORGANIZATION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ORGANIZATION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createOrganization ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_CREATE_PENDING)
      const res = await this.$axios.post('organizations', payload)
      commit(C.ORGANIZATION_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrganization ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_UPDATE_PENDING)
      const res = await this.$axios.put(`organizations/${payload.id}`, payload)
      commit(C.ORGANIZATION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteOrganization ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_DELETE_PENDING)
      const res = await this.$axios.delete(`organizations/${payload}`)
      commit(C.ORGANIZATION_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getOrganizationUsers ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_USERS_PENDING)
      const res = await this.$axios.get('organizations/users', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.ORGANIZATION_USERS_SUCCESS, res.data)
      } else {
        commit(C.ORGANIZATION_USERS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.ORGANIZATION_USERS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getOrganizationFromSession ({ commit }) {
    try {
      commit(C.ORGANIZATION_SESSION_PENDING)
      const res = await this.$axios.get('organizations/sessions')
      commit(C.ORGANIZATION_SESSION_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_SESSION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async setOrganizationInSession ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_SESSION_PENDING)
      const res = await this.$axios.put('organizations/sessions', { organization_id: payload })
      commit(C.ORGANIZATION_SESSION_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_SESSION_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getOrganizationOnboarding ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_ONBOARDING_PENDING)
      const res = await this.$axios.get('organizations/onboarding')
      commit(C.ORGANIZATION_ONBOARDING_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_ONBOARDING_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateOrganizationOnboarding ({ commit }, payload) {
    try {
      commit(C.ORGANIZATION_UPDATE_PENDING)
      const res = await this.$axios.put(`organizations/${payload.id}/onboarding`, payload)
      commit(C.ORGANIZATION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getOrganizationSubscriptionStats ({ commit }) {
    try {
      commit(C.ORGANIZATION_SUBSCRIPTION_STATS_PENDING)
      const res = await this.$axios.get('organizations/subscription-stats')
      commit(C.ORGANIZATION_SUBSCRIPTION_STATS_SUCCESS, res.data)
    } catch (err) {
      commit(C.ORGANIZATION_SUBSCRIPTION_STATS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetOrganizationStore ({ commit }) {
    commit(C.ORGANIZATION_RESET_STORE)
  }
}
