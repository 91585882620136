import Vue from 'vue'
Vue.filter('address', function (address, showCity = false) {
  if (!address) { return '' }
  let addressStr = ''
  addressStr += address.street
  if (address.street_no_from) {
    addressStr += ` ${address.street_no_from}`
    if (address.street_letter_from) {
      addressStr += `${address.street_letter_from}`
    }
  }
  if (address.street_no_to) {
    addressStr += `-${address.street_no_to}`
    if (address.street_letter_to) {
      addressStr += `${address.street_letter_to}`
    }
  }
  if (address.floor) {
    addressStr += `, ${address.floor}`
  }
  if (address.door) {
    addressStr += ` ${address.door}`
  }
  if (showCity) {
    if (address.city.zip && address.city.name) {
      addressStr += `, ${address.city.zip} ${address.city.name}`
    }
  }
  return addressStr
})
