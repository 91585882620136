import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getDeviationIndex ({ commit }, payload) {
    try {
      commit(C.DEVIATION_INDEX_PENDING)
      const res = await this.$axios.get('deviations', { params: payload })
      if (res.status === 200) {
        commit(C.DEVIATION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.DEVIATION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.DEVIATION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getDeviationCount ({ commit }, payload) {
    try {
      commit(C.DEVIATION_COUNT_PENDING)
      const res = await this.$axios.get('deviations/count', { params: payload })
      commit(C.DEVIATION_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getDeviationCalendar ({ commit }, payload) {
    try {
      commit(C.DEVIATION_CALENDAR_PENDING)
      const res = await this.$axios.get('deviations/calendar', { params: payload })
      commit(C.DEVIATION_CALENDAR_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_CALENDAR_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async addDeviationTasks ({ commit }, payload) {
    try {
      commit(C.DEVIATION_UPDATE_PENDING)
      const res = await this.$axios.post(`deviations/${payload.id}/tasks`, payload)
      commit(C.DEVIATION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getDeviationIndexByReport ({ commit }, payload) {
    try {
      commit(C.DEVIATION_REPORT_PENDING)
      const res = await this.$axios.get(`deviations/report/${payload}`)
      if (res.status === 200) {
        commit(C.DEVIATION_REPORT_SUCCESS, res.data)
      } else {
        commit(C.DEVIATION_REPORT_NO_CONTENT)
      }
    } catch (err) {
      commit(C.DEVIATION_REPORT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getDeviation ({ commit }, payload) {
    try {
      commit(C.DEVIATION_SHOW_PENDING)
      const res = await this.$axios.get(`deviations/${payload}`)
      commit(C.DEVIATION_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createDeviation ({ commit }, payload) {
    const formData = new FormData()
    // Add elements from form
    Object.entries(payload.form).forEach(([key, value]) => {
      if (value && key !== 'address') {
        formData.append(key, value)
      } else if (value && key === 'address') {
        // Add elements from form.address
        Object.entries(value).forEach(([addressKey, addressValue]) => {
          if (addressValue && addressKey !== 'city') {
            formData.append(`address[${addressKey}]`, addressValue)
          } else if (addressValue && addressKey === 'city') {
            // Add elements from form.address.city
            Object.entries(addressValue).forEach(([cityKey, cityValue]) => {
              formData.append(`address[city][${cityKey}]`, cityValue)
            })
          }
        })
      }
    })
    // Add images from form
    payload.images.forEach((image) => {
      formData.append('images[]', image)
    })
    try {
      commit(C.DEVIATION_CREATE_PENDING)
      const res = await this.$axios.post('deviations', formData, {
        headers: {
          'Content-type': 'multipart/form-data'
        }
      })
      commit(C.DEVIATION_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateDeviation ({ commit }, payload) {
    try {
      commit(C.DEVIATION_UPDATE_PENDING)
      const res = await this.$axios.put(`deviations/${payload.id}`, payload)
      commit(C.DEVIATION_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async completeDeviation ({ commit }, payload) {
    try {
      commit(C.DEVIATION_COMPLETE_PENDING)
      const res = await this.$axios.get(`deviations/${payload}/complete`)
      commit(C.DEVIATION_COMPLETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_COMPLETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteDeviation ({ commit }, payload) {
    try {
      commit(C.DEVIATION_DELETE_PENDING)
      const res = await this.$axios.delete(`deviations/${payload}`)
      commit(C.DEVIATION_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.DEVIATION_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getDeviationTypeIndex ({ commit }) {
    try {
      commit(C.DEVIATION_TYPE_INDEX_PENDING)
      const res = await this.$axios.get('deviation-types')
      if (res.status === 200) {
        commit(C.DEVIATION_TYPE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.DEVIATION_TYPE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.DEVIATION_TYPE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async downloadDeviation ({ commit }, payload) {
    try {
      commit(C.DEVIATION_DOWNLOAD_PENDING)
      const res = await this.$axios.get(`deviations/${payload.id}/pdf`)
      if (res.status === 200) {
        commit(C.DEVIATION_DOWNLOAD_SUCCESS, res.data)
      } else {
        commit(C.DEVIATION_DOWNLOAD_NO_CONTENT)
      }
    } catch (err) {
      commit(C.DEVIATION_DOWNLOAD_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetDeviationStore ({ commit }) {
    commit(C.DEVIATION_RESET_STORE)
  }
}
