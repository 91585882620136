import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createRiskScenario ({ commit }, payload) {
    try {
      commit(C.RISK_SCENARIO_CREATE_PENDING)
      const res = await this.$axios.post('risk-scenarios', payload)
      commit(C.RISK_SCENARIO_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_SCENARIO_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateRiskScenario ({ commit }, payload) {
    try {
      commit(C.RISK_SCENARIO_UPDATE_PENDING)
      const res = await this.$axios.put(`risk-scenarios/${payload.id}`, payload)
      commit(C.RISK_SCENARIO_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_SCENARIO_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteRiskScenario ({ commit }, payload) {
    try {
      commit(C.RISK_SCENARIO_DELETE_PENDING)
      const res = await this.$axios.delete(`risk-scenarios/${payload.id}`)
      commit(C.RISK_SCENARIO_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_SCENARIO_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRiskScenarioStore ({ commit }) {
    commit(C.RISK_SCENARIO_RESET_STORE)
  }
}
