import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRiskIndex ({ commit }, payload) {
    try {
      commit(C.RISK_INDEX_PENDING)
      const res = await this.$axios.get('risks', { params: payload })
      if (res.status === 200) {
        commit(C.RISK_INDEX_SUCCESS, res.data)
      } else {
        commit(C.RISK_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRisk ({ commit }, payload) {
    try {
      commit(C.RISK_SHOW_PENDING)
      const res = await this.$axios.get(`risks/${payload}`)
      if (res.status === 200) {
        commit(C.RISK_SHOW_SUCCESS, res.data)
      } else {
        commit(C.RISK_SHOW_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createRisk ({ commit }, payload) {
    try {
      commit(C.RISK_CREATE_PENDING)
      const res = await this.$axios.post('risks', payload)
      commit(C.RISK_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateRisk ({ commit }, payload) {
    try {
      commit(C.RISK_UPDATE_PENDING)
      const res = await this.$axios.put(`risks/${payload.id}`, payload)
      commit(C.RISK_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteRisk ({ commit }, payload) {
    try {
      commit(C.RISK_DELETE_PENDING)
      const res = await this.$axios.delete(`risks/${payload.id}`)
      commit(C.RISK_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async checkRiskExists ({ commit }, payload) {
    try {
      commit(C.RISK_EXISTS_PENDING)
      const res = await this.$axios.post('risks/check-if-exists', payload)
      if (res.status === 200) {
        commit(C.RISK_EXISTS_SUCCESS, res.data)
      } else {
        commit(C.RISK_EXISTS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_EXISTS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetRiskExists ({ commit }) {
    commit(C.RISK_EXISTS_RESET)
  },

  /**
   * @param commit
   */
  resetRiskStore ({ commit }) {
    commit(C.RISK_RESET_STORE)
  }
}
