const middleware = {}

middleware['adminOnly'] = require('../middleware/adminOnly.js')
middleware['adminOnly'] = middleware['adminOnly'].default || middleware['adminOnly']

middleware['permission'] = require('../middleware/permission.js')
middleware['permission'] = middleware['permission'].default || middleware['permission']

middleware['query'] = require('../middleware/query.js')
middleware['query'] = middleware['query'].default || middleware['query']

export default middleware
