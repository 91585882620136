// ORGANIZATION CONSTANTS
export const COUNTRY_INDEX_PENDING = 'COUNTRY_INDEX_PENDING'
export const COUNTRY_INDEX_SUCCESS = 'COUNTRY_INDEX_SUCCESS'
export const COUNTRY_INDEX_FAILURE = 'COUNTRY_INDEX_FAILURE'
export const COUNTRY_INDEX_NO_CONTENT = 'COUNTRY_INDEX_NO_CONTENT'

export const COUNTRY_ALL_PENDING = 'COUNTRY_ALL_PENDING'
export const COUNTRY_ALL_SUCCESS = 'COUNTRY_ALL_SUCCESS'
export const COUNTRY_ALL_FAILURE = 'COUNTRY_ALL_FAILURE'

export const COUNTRY_PREFERRED_PENDING = 'COUNTRY_PREFERRED_PENDING'
export const COUNTRY_PREFERRED_SUCCESS = 'COUNTRY_PREFERRED_SUCCESS'
export const COUNTRY_PREFERRED_FAILURE = 'COUNTRY_PREFERRED_FAILURE'

export const COUNTRY_RESET_STORE = 'COUNTRY_RESET_STORE'
