export default () => {
  return {
    verifyEmailModal: {
      show: false
    },
    session: {
      expired: false
    },
    storedQuery: {
      path: null,
      query: null
    },
    filters: null,
    sidebarCollapsed: false,
    sidebarToggled: false
  }
}
