// RISK LEVEL CONSTANTS
export const RISK_DEFINITION_INDEX_PENDING = 'RISK_DEFINITION_INDEX_PENDING'
export const RISK_DEFINITION_INDEX_SUCCESS = 'RISK_DEFINITION_INDEX_SUCCESS'
export const RISK_DEFINITION_INDEX_FAILURE = 'RISK_DEFINITION_INDEX_FAILURE'
export const RISK_DEFINITION_INDEX_NO_CONTENT = 'RISK_DEFINITION_INDEX_NO_CONTENT'

export const RISK_DEFINITION_UPDATE_PENDING = 'RISK_DEFINITION_UPDATE_PENDING'
export const RISK_DEFINITION_UPDATE_SUCCESS = 'RISK_DEFINITION_UPDATE_SUCCESS'
export const RISK_DEFINITION_UPDATE_FAILURE = 'RISK_DEFINITION_UPDATE_FAILURE'

export const RISK_DEFINITION_DELETE_PENDING = 'RISK_DEFINITION_DELETE_PENDING'
export const RISK_DEFINITION_DELETE_SUCCESS = 'RISK_DEFINITION_DELETE_SUCCESS'
export const RISK_DEFINITION_DELETE_FAILURE = 'RISK_DEFINITION_DELETE_FAILURE'

export const RISK_DEFINITION_RESET_STORE = 'RISK_DEFINITION_RESET_STORE'
