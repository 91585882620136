import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierIndex ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_INDEX_PENDING)
      const res = await this.$axios.get('suppliers', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_INDEX_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierList ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_LIST_PENDING)
      const res = await this.$axios.get('suppliers/list')
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_LIST_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_LIST_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_LIST_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSupplier ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_SHOW_PENDING)
      const res = await this.$axios.get(`suppliers/${payload}`)
      commit(C.SUPPLIER_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createSupplier ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_CREATE_PENDING)
      const res = await this.$axios.post('suppliers', payload)
      commit(C.SUPPLIER_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateSupplier ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_UPDATE_PENDING)
      const res = await this.$axios.put(`suppliers/${payload.id}`, payload)
      commit(C.SUPPLIER_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSupplier ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_DELETE_PENDING)
      const res = await this.$axios.delete(`suppliers/${payload.id}`)
      commit(C.SUPPLIER_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSupplierAndTasks ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_DELETE_PENDING)
      const res = await this.$axios.delete(`suppliers/${payload.id}/tasks`)
      commit(C.SUPPLIER_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTasks ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TASKS_PENDING)
      const res = await this.$axios.get(`suppliers/${payload.id}/tasks`, { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TASKS_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TASKS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TASKS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTasksCount ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TASKS_COUNT_PENDING)
      const res = await this.$axios.get(`suppliers/${payload.id}/tasks/count`, { params: payload })
      commit(C.SUPPLIER_TASKS_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TASKS_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetSupplierStore ({ commit }) {
    commit(C.SUPPLIER_RESET_STORE)
  }
}
