import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getNotificationIndex ({ commit }, payload = { page: 1, 'per-page': 10 }) {
    try {
      commit(C.NOTIFICATION_INDEX_PENDING)
      const res = await this.$axios.get('notifications', { params: payload })
      if (res.status === 200) {
        commit(C.NOTIFICATION_INDEX_SUCCESS, res.data)
      } else {
        commit(C.NOTIFICATION_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.NOTIFICATION_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async allReadNotifications ({ commit }) {
    try {
      commit(C.NOTIFICATION_READ_PENDING)
      const res = await this.$axios.put('notifications/all-read')
      commit(C.NOTIFICATION_READ_SUCCESS, res.data)
    } catch (err) {
      commit(C.NOTIFICATION_READ_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getUnreadNotifications ({ commit }) {
    try {
      commit(C.NOTIFICATION_UNREAD_PENDING)
      const res = await this.$axios.get('notifications/unread')
      if (res.status === 200) {
        commit(C.NOTIFICATION_UNREAD_SUCCESS, res.data)
      } else {
        commit(C.NOTIFICATION_UNREAD_NO_CONTENT)
      }
    } catch (err) {
      commit(C.NOTIFICATION_UNREAD_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getUnreadNotificationCount ({ commit }) {
    try {
      commit(C.NOTIFICATION_UNREAD_COUNT_PENDING)
      const res = await this.$axios.get('notifications/unread/count')
      commit(C.NOTIFICATION_UNREAD_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.NOTIFICATION_UNREAD_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateReadNotifications ({ commit }, payload) {
    try {
      commit(C.NOTIFICATION_READ_PENDING)
      const res = await this.$axios.put(`notifications/${payload}/read`)
      if (res.status === 200) {
        commit(C.NOTIFICATION_READ_SUCCESS, payload.notification_ids)
      } else {
        commit(C.NOTIFICATION_READ_FAILURE, null)
      }
    } catch (err) {
      commit(C.NOTIFICATION_READ_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetNotificationStore ({ commit }) {
    commit(C.NOTIFICATION_RESET_STORE)
  }
}
