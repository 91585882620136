// UNIT CONSTANTS
export const SUPPLIER_TYPE_INDEX_PENDING = 'SUPPLIER_TYPE_INDEX_PENDING'
export const SUPPLIER_TYPE_INDEX_SUCCESS = 'SUPPLIER_TYPE_INDEX_SUCCESS'
export const SUPPLIER_TYPE_INDEX_FAILURE = 'SUPPLIER_TYPE_INDEX_FAILURE'
export const SUPPLIER_TYPE_INDEX_NO_CONTENT = 'SUPPLIER_TYPE_INDEX_NO_CONTENT'

export const SUPPLIER_TYPE_LIST_PENDING = 'SUPPLIER_TYPE_LIST_PENDING'
export const SUPPLIER_TYPE_LIST_SUCCESS = 'SUPPLIER_TYPE_LIST_SUCCESS'
export const SUPPLIER_TYPE_LIST_FAILURE = 'SUPPLIER_TYPE_LIST_FAILURE'
export const SUPPLIER_TYPE_LIST_NO_CONTENT = 'SUPPLIER_TYPE_LIST_NO_CONTENT'

export const SUPPLIER_TYPE_SHOW_PENDING = 'SUPPLIER_TYPE_SHOW_PENDING'
export const SUPPLIER_TYPE_SHOW_SUCCESS = 'SUPPLIER_TYPE_SHOW_SUCCESS'
export const SUPPLIER_TYPE_SHOW_FAILURE = 'SUPPLIER_TYPE_SHOW_FAILURE'

export const SUPPLIER_TYPE_CREATE_PENDING = 'SUPPLIER_TYPE_CREATE_PENDING'
export const SUPPLIER_TYPE_CREATE_SUCCESS = 'SUPPLIER_TYPE_CREATE_SUCCESS'
export const SUPPLIER_TYPE_CREATE_FAILURE = 'SUPPLIER_TYPE_CREATE_FAILURE'

export const SUPPLIER_TYPE_UPDATE_PENDING = 'SUPPLIER_TYPE_UPDATE_PENDING'
export const SUPPLIER_TYPE_UPDATE_SUCCESS = 'SUPPLIER_TYPE_UPDATE_SUCCESS'
export const SUPPLIER_TYPE_UPDATE_FAILURE = 'SUPPLIER_TYPE_UPDATE_FAILURE'

export const SUPPLIER_TYPE_DELETE_PENDING = 'SUPPLIER_TYPE_DELETE_PENDING'
export const SUPPLIER_TYPE_DELETE_SUCCESS = 'SUPPLIER_TYPE_DELETE_SUCCESS'
export const SUPPLIER_TYPE_DELETE_FAILURE = 'SUPPLIER_TYPE_DELETE_FAILURE'

export const SUPPLIER_TYPE_TASKS_PENDING = 'SUPPLIER_TYPE_TASKS_PENDING'
export const SUPPLIER_TYPE_TASKS_SUCCESS = 'SUPPLIER_TYPE_TASKS_SUCCESS'
export const SUPPLIER_TYPE_TASKS_FAILURE = 'SUPPLIER_TYPE_TASKS_FAILURE'
export const SUPPLIER_TYPE_TASKS_NO_CONTENT = 'SUPPLIER_TYPE_TASKS_NO_CONTENT'

export const SUPPLIER_TYPE_TASKS_COUNT_PENDING = 'SUPPLIER_TYPE_TASKS_COUNT_PENDING'
export const SUPPLIER_TYPE_TASKS_COUNT_SUCCESS = 'SUPPLIER_TYPE_TASKS_COUNT_SUCCESS'
export const SUPPLIER_TYPE_TASKS_COUNT_FAILURE = 'SUPPLIER_TYPE_TASKS_COUNT_FAILURE'

export const SUPPLIER_TYPE_RESET_STORE = 'SUPPLIER_TYPE_RESET_STORE'
