import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeCriteriaIndex ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_INDEX_PENDING)
      const res = await this.$axios.get('supplier-type-criteria', { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TYPE_CRITERIA_INDEX_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TYPE_CRITERIA_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeCriteriaList ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_LIST_PENDING)
      const res = await this.$axios.get('supplier-type-criteria/list')
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TYPE_CRITERIA_LIST_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TYPE_CRITERIA_LIST_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_LIST_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getSupplierTypeCriteria ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_SHOW_PENDING)
      const res = await this.$axios.get(`supplier-type-criteria/${payload}`)
      commit(C.SUPPLIER_TYPE_CRITERIA_SHOW_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_SHOW_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createSupplierTypeCriteria ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_CREATE_PENDING)
      const res = await this.$axios.post('supplier-type-criteria', payload)
      commit(C.SUPPLIER_TYPE_CRITERIA_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateSupplierTypeCriteria ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_UPDATE_PENDING)
      const res = await this.$axios.put(`supplier-type-criteria/${payload.id}`, payload)
      commit(C.SUPPLIER_TYPE_CRITERIA_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSupplierTypeCriteria ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_DELETE_PENDING)
      const res = await this.$axios.delete(`supplier-type-criteria/${payload.id}`)
      commit(C.SUPPLIER_TYPE_CRITERIA_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteSupplierTypeCriteriaAndTasks ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_DELETE_PENDING)
      const res = await this.$axios.delete(`supplier-type-criteria/${payload.id}/tasks`)
      commit(C.SUPPLIER_TYPE_CRITERIA_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeCriteriaTasks ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_PENDING)
      const res = await this.$axios.get(`supplier-type-criteria/${payload.id}/tasks`, { params: payload })
      if (res.status === 200 && res.data) {
        commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_SUCCESS, res.data)
      } else {
        commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @param $config
   * @returns {Promise<void>}
   */
  async getSupplierTypeCriteriaTasksCount ({ commit }, payload) {
    try {
      commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_COUNT_PENDING)
      const res = await this.$axios.get(`supplier-type-criteria/${payload.id}/tasks/count`, { params: payload })
      commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_COUNT_SUCCESS, res.data)
    } catch (err) {
      commit(C.SUPPLIER_TYPE_CRITERIA_TASKS_COUNT_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  resetSupplierTypeCriteriaStore ({ commit }) {
    commit(C.SUPPLIER_TYPE_CRITERIA_RESET_STORE)
  }
}
