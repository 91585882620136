import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getRiskCriteriaIndex ({ commit }) {
    try {
      commit(C.RISK_CRITERIA_INDEX_PENDING)
      const res = await this.$axios.get('risk-criteria')
      if (res.status === 200 || res.status === 201) {
        commit(C.RISK_CRITERIA_INDEX_SUCCESS, res.data)
      } else {
        commit(C.RISK_CRITERIA_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_CRITERIA_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateRiskCriteria ({ commit }, payload) {
    try {
      commit(C.RISK_CRITERIA_UPDATE_PENDING)
      const res = await this.$axios.put(`risk-criteria/${payload.id}`, payload)
      commit(C.RISK_CRITERIA_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_CRITERIA_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteRiskCriteria ({ commit }, payload) {
    try {
      commit(C.RISK_CRITERIA_DELETE_PENDING)
      const res = await this.$axios.delete(`risk-criteria/${payload.id}`)
      commit(C.RISK_CRITERIA_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_CRITERIA_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRiskCriteriaStore ({ commit }) {
    commit(C.RISK_CRITERIA_RESET_STORE)
  }
}
