import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.RISK_INDEX_PENDING] (state) {
    set(state, 'index', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.RISK_INDEX_NO_CONTENT] (state) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.RISK_SHOW_PENDING] (state) {
    set(state, 'show', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_SHOW_SUCCESS] (state, payload) {
    set(state, 'show', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_SHOW_FAILURE] (state, payload) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.RISK_SHOW_NO_CONTENT] (state) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.RISK_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.RISK_UPDATE_PENDING] (state) {
    set(state, 'update', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.RISK_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.RISK_DELETE_SUCCESS] (state) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.RISK_EXISTS_PENDING] (state) {
    set(state, 'exists', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_EXISTS_SUCCESS] (state, payload) {
    set(state, 'exists', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.RISK_EXISTS_FAILURE] (state, payload) {
    set(state, 'exists', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.RISK_EXISTS_NO_CONTENT] (state) {
    set(state, 'exists', {
      item: null,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.RISK_EXISTS_RESET] (state) {
    set(state, 'exists', {
      item: null,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.RISK_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
