import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ba4df5c0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _e5bce28e = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _0356e575 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _485e7b4e = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _7b557074 = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _f9733924 = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _27c6355c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _9a4c93ec = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _fb269dda = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _043ab85e = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _8c2e5114 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _acae7e34 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _40d77f92 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _5b676a12 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _3ae160aa = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _4c0e653c = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _a83185f2 = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _6f476ccf = () => interopDefault(import('../pages/supplier/index.vue' /* webpackChunkName: "pages/supplier/index" */))
const _a9ea6d70 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _ceac8af2 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _1ea8e13c = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _38ea68ee = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _54417d86 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _3781b93c = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _307a2ec0 = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _511c7cc2 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _69f87dff = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _d5fd68b6 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _b2732240 = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _14d0a03e = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _257be93d = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _f4040aae = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _398ad34c = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _a37a88ec = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _6eeb49c4 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _6d32bae0 = () => interopDefault(import('../pages/supplier/type/index.vue' /* webpackChunkName: "pages/supplier/type/index" */))
const _5bf39338 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _66b2a524 = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _29cd3f39 = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _7059315f = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _60441759 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _155730bc = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _c50da806 = () => interopDefault(import('../pages/risk/_id/index.vue' /* webpackChunkName: "pages/risk/_id/index" */))
const _333c7c72 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _3020ef8c = () => interopDefault(import('../pages/supplier/_id/index.vue' /* webpackChunkName: "pages/supplier/_id/index" */))
const _50f78c1c = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _7307cae2 = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _4de8a7a8 = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _10df3afc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _ba4df5c0,
    name: "account"
  }, {
    path: "/action",
    component: _e5bce28e,
    name: "action"
  }, {
    path: "/archive",
    component: _0356e575,
    name: "archive"
  }, {
    path: "/audit",
    component: _485e7b4e,
    name: "audit"
  }, {
    path: "/deviation",
    component: _7b557074,
    name: "deviation"
  }, {
    path: "/it",
    component: _f9733924,
    name: "it"
  }, {
    path: "/login",
    component: _27c6355c,
    name: "login"
  }, {
    path: "/logout",
    component: _9a4c93ec,
    name: "logout"
  }, {
    path: "/offline",
    component: _fb269dda,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _043ab85e,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _8c2e5114,
    name: "organization"
  }, {
    path: "/register",
    component: _acae7e34,
    name: "register"
  }, {
    path: "/report",
    component: _40d77f92,
    name: "report"
  }, {
    path: "/risk",
    component: _5b676a12,
    name: "risk"
  }, {
    path: "/search",
    component: _3ae160aa,
    name: "search"
  }, {
    path: "/styles",
    component: _4c0e653c,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _a83185f2,
    name: "suggestion"
  }, {
    path: "/supplier",
    component: _6f476ccf,
    name: "supplier"
  }, {
    path: "/task",
    component: _a9ea6d70,
    name: "task"
  }, {
    path: "/unit",
    component: _ceac8af2,
    name: "unit"
  }, {
    path: "/verify",
    component: _1ea8e13c,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _38ea68ee,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _54417d86,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _3781b93c,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _307a2ec0,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _511c7cc2,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _69f87dff,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _d5fd68b6,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _b2732240,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _14d0a03e,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _257be93d,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _f4040aae,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _398ad34c,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _a37a88ec,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _6eeb49c4,
    name: "password-reset"
  }, {
    path: "/supplier/type",
    component: _6d32bae0,
    name: "supplier-type"
  }, {
    path: "/task/group",
    component: _5bf39338,
    name: "task-group"
  }, {
    path: "/action/:id",
    component: _66b2a524,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _29cd3f39,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _7059315f,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _60441759,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _155730bc,
    name: "report-id"
  }, {
    path: "/risk/:id",
    component: _c50da806,
    name: "risk-id"
  }, {
    path: "/suggestion/:id",
    component: _333c7c72,
    name: "suggestion-id"
  }, {
    path: "/supplier/:id",
    component: _3020ef8c,
    name: "supplier-id"
  }, {
    path: "/unit/:id",
    component: _50f78c1c,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _7307cae2,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _4de8a7a8,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _10df3afc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
