import * as C from './constants'

export default {

  /**
   * @param commit
   * @returns {Promise<void>}
   */
  async getCompanyUnitIndex ({ commit }) {
    try {
      commit(C.COMPANY_UNIT_INDEX_PENDING)
      const res = await this.$axios.get('company-units')
      if (res.status === 200 && res.data) {
        commit(C.COMPANY_UNIT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.COMPANY_UNIT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.COMPANY_UNIT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createCompanyUnit ({ commit }, payload) {
    try {
      commit(C.COMPANY_UNIT_CREATE_PENDING)
      const res = await this.$axios.post('company-units', payload)
      commit(C.COMPANY_UNIT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_UNIT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateCompanyUnit ({ commit }, payload) {
    try {
      commit(C.COMPANY_UNIT_UPDATE_PENDING)
      const res = await this.$axios.put(`company-units/${payload.id}`, payload)
      commit(C.COMPANY_UNIT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_UNIT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteCompanyUnit ({ commit }, payload) {
    try {
      commit(C.COMPANY_UNIT_DELETE_PENDING)
      const res = await this.$axios.delete(`company-units/${payload.id}`)
      commit(C.COMPANY_UNIT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.COMPANY_UNIT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetCompanyUnitStore ({ commit }) {
    commit(C.COMPANY_UNIT_RESET_STORE)
  }
}
