// UNIT CONSTANTS
export const SUPPLIER_CRITERIA_INDEX_PENDING = 'SUPPLIER_CRITERIA_INDEX_PENDING'
export const SUPPLIER_CRITERIA_INDEX_SUCCESS = 'SUPPLIER_CRITERIA_INDEX_SUCCESS'
export const SUPPLIER_CRITERIA_INDEX_FAILURE = 'SUPPLIER_CRITERIA_INDEX_FAILURE'
export const SUPPLIER_CRITERIA_INDEX_NO_CONTENT = 'SUPPLIER_CRITERIA_INDEX_NO_CONTENT'

export const SUPPLIER_CRITERIA_LIST_PENDING = 'SUPPLIER_CRITERIA_LIST_PENDING'
export const SUPPLIER_CRITERIA_LIST_SUCCESS = 'SUPPLIER_CRITERIA_LIST_SUCCESS'
export const SUPPLIER_CRITERIA_LIST_FAILURE = 'SUPPLIER_CRITERIA_LIST_FAILURE'
export const SUPPLIER_CRITERIA_LIST_NO_CONTENT = 'SUPPLIER_CRITERIA_LIST_NO_CONTENT'

export const SUPPLIER_CRITERIA_SHOW_PENDING = 'SUPPLIER_CRITERIA_SHOW_PENDING'
export const SUPPLIER_CRITERIA_SHOW_SUCCESS = 'SUPPLIER_CRITERIA_SHOW_SUCCESS'
export const SUPPLIER_CRITERIA_SHOW_FAILURE = 'SUPPLIER_CRITERIA_SHOW_FAILURE'

export const SUPPLIER_CRITERIA_CREATE_PENDING = 'SUPPLIER_CRITERIA_CREATE_PENDING'
export const SUPPLIER_CRITERIA_CREATE_SUCCESS = 'SUPPLIER_CRITERIA_CREATE_SUCCESS'
export const SUPPLIER_CRITERIA_CREATE_FAILURE = 'SUPPLIER_CRITERIA_CREATE_FAILURE'

export const SUPPLIER_CRITERIA_UPDATE_PENDING = 'SUPPLIER_CRITERIA_UPDATE_PENDING'
export const SUPPLIER_CRITERIA_UPDATE_SUCCESS = 'SUPPLIER_CRITERIA_UPDATE_SUCCESS'
export const SUPPLIER_CRITERIA_UPDATE_FAILURE = 'SUPPLIER_CRITERIA_UPDATE_FAILURE'

export const SUPPLIER_CRITERIA_DELETE_PENDING = 'SUPPLIER_CRITERIA_DELETE_PENDING'
export const SUPPLIER_CRITERIA_DELETE_SUCCESS = 'SUPPLIER_CRITERIA_DELETE_SUCCESS'
export const SUPPLIER_CRITERIA_DELETE_FAILURE = 'SUPPLIER_CRITERIA_DELETE_FAILURE'

export const SUPPLIER_CRITERIA_TASKS_PENDING = 'SUPPLIER_CRITERIA_TASKS_PENDING'
export const SUPPLIER_CRITERIA_TASKS_SUCCESS = 'SUPPLIER_CRITERIA_TASKS_SUCCESS'
export const SUPPLIER_CRITERIA_TASKS_FAILURE = 'SUPPLIER_CRITERIA_TASKS_FAILURE'
export const SUPPLIER_CRITERIA_TASKS_NO_CONTENT = 'SUPPLIER_CRITERIA_TASKS_NO_CONTENT'

export const SUPPLIER_CRITERIA_TASKS_COUNT_PENDING = 'SUPPLIER_CRITERIA_TASKS_COUNT_PENDING'
export const SUPPLIER_CRITERIA_TASKS_COUNT_SUCCESS = 'SUPPLIER_CRITERIA_TASKS_COUNT_SUCCESS'
export const SUPPLIER_CRITERIA_TASKS_COUNT_FAILURE = 'SUPPLIER_CRITERIA_TASKS_COUNT_FAILURE'

export const SUPPLIER_CRITERIA_RESET_STORE = 'SUPPLIER_CRITERIA_RESET_STORE'
