import Vue from 'vue'

Vue.directive('visibility', {
  bind (el, binding) {
    const options = binding.value.options || { threshold: 0.5 }
    const callback = binding.value.callback

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => callback(entry))
    }, options)

    observer.observe(el)
    el._observer = observer
  },
  unbind (el) {
    if (el._observer) {
      el._observer.disconnect()
      delete el._observer
    }
  }
})
