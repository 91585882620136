// RISK ASSESSMENT CONSTANTS
export const RISK_ASSESSMENT_INDEX_PENDING = 'RISK_ASSESSMENT_INDEX_PENDING'
export const RISK_ASSESSMENT_INDEX_SUCCESS = 'RISK_ASSESSMENT_INDEX_SUCCESS'
export const RISK_ASSESSMENT_INDEX_FAILURE = 'RISK_ASSESSMENT_INDEX_FAILURE'
export const RISK_ASSESSMENT_INDEX_NO_CONTENT = 'RISK_ASSESSMENT_INDEX_NO_CONTENT'

export const RISK_ASSESSMENT_CREATE_PENDING = 'RISK_ASSESSMENT_CREATE_PENDING'
export const RISK_ASSESSMENT_CREATE_SUCCESS = 'RISK_ASSESSMENT_CREATE_SUCCESS'
export const RISK_ASSESSMENT_CREATE_FAILURE = 'RISK_ASSESSMENT_CREATE_FAILURE'

export const RISK_ASSESSMENT_UPDATE_PENDING = 'RISK_ASSESSMENT_UPDATE_PENDING'
export const RISK_ASSESSMENT_UPDATE_SUCCESS = 'RISK_ASSESSMENT_UPDATE_SUCCESS'
export const RISK_ASSESSMENT_UPDATE_FAILURE = 'RISK_ASSESSMENT_UPDATE_FAILURE'

export const RISK_ASSESSMENT_DELETE_PENDING = 'RISK_ASSESSMENT_DELETE_PENDING'
export const RISK_ASSESSMENT_DELETE_SUCCESS = 'RISK_ASSESSMENT_DELETE_SUCCESS'
export const RISK_ASSESSMENT_DELETE_FAILURE = 'RISK_ASSESSMENT_DELETE_FAILURE'

export const RISK_ASSESSMENT_HISTORY_PENDING = 'RISK_ASSESSMENT_HISTORY_PENDING'
export const RISK_ASSESSMENT_HISTORY_SUCCESS = 'RISK_ASSESSMENT_HISTORY_SUCCESS'
export const RISK_ASSESSMENT_HISTORY_FAILURE = 'RISK_ASSESSMENT_HISTORY_FAILURE'
export const RISK_ASSESSMENT_HISTORY_NO_CONTENT = 'RISK_ASSESSMENT_HISTORY_NO_CONTENT'

export const RISK_ASSESSMENT_RESET_STORE = 'RISK_ASSESSMENT_RESET_STORE'
