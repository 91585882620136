import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   * @param payload
   */
  [C.APP_TOGGLE_VERIFY_EMAIL_MODAL] (state, payload) {
    set(state, 'verifyEmailModal', {
      show: payload !== null ? payload : !state.verifyEmailModal.show
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.APP_TOGGLE_SIDEBAR] (state, payload) {
    set(state, 'sidebarToggled', payload)
  },

  /**
   * @param state
   * @param payload
   */
  [C.APP_TOGGLE_SIDEBAR] (state, payload) {
    set(state, 'sidebarToggled', payload)
  },

  /**
   * @param state
   * @param payload
   */
  [C.APP_COLLAPSE_SIDEBAR] (state, payload) {
    set(state, 'sidebarCollapsed', payload)
  },

  /**
   * @param state
   * @param payload
   */
  [C.APP_TOGGLE_SESSION_EXPIRED] (state, payload) {
    set(state, 'session', {
      expired: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.APP_STORE_QUERY] (state, payload) {
    set(state, 'storedQuery', {
      ...state.storedQuery,
      ...payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.APP_SET_FILTERS] (state, payload) {
    set(state, 'filters', {
      filters: payload
    })
  },

  /**
   * @param state
   */
  [C.APP_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
