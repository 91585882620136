// UNIT CONSTANTS
export const SUPPLIER_INDEX_PENDING = 'SUPPLIER_INDEX_PENDING'
export const SUPPLIER_INDEX_SUCCESS = 'SUPPLIER_INDEX_SUCCESS'
export const SUPPLIER_INDEX_FAILURE = 'SUPPLIER_INDEX_FAILURE'
export const SUPPLIER_INDEX_NO_CONTENT = 'SUPPLIER_INDEX_NO_CONTENT'

export const SUPPLIER_LIST_PENDING = 'SUPPLIER_LIST_PENDING'
export const SUPPLIER_LIST_SUCCESS = 'SUPPLIER_LIST_SUCCESS'
export const SUPPLIER_LIST_FAILURE = 'SUPPLIER_LIST_FAILURE'
export const SUPPLIER_LIST_NO_CONTENT = 'SUPPLIER_LIST_NO_CONTENT'

export const SUPPLIER_SHOW_PENDING = 'SUPPLIER_SHOW_PENDING'
export const SUPPLIER_SHOW_SUCCESS = 'SUPPLIER_SHOW_SUCCESS'
export const SUPPLIER_SHOW_FAILURE = 'SUPPLIER_SHOW_FAILURE'

export const SUPPLIER_CREATE_PENDING = 'SUPPLIER_CREATE_PENDING'
export const SUPPLIER_CREATE_SUCCESS = 'SUPPLIER_CREATE_SUCCESS'
export const SUPPLIER_CREATE_FAILURE = 'SUPPLIER_CREATE_FAILURE'

export const SUPPLIER_UPDATE_PENDING = 'SUPPLIER_UPDATE_PENDING'
export const SUPPLIER_UPDATE_SUCCESS = 'SUPPLIER_UPDATE_SUCCESS'
export const SUPPLIER_UPDATE_FAILURE = 'SUPPLIER_UPDATE_FAILURE'

export const SUPPLIER_DELETE_PENDING = 'SUPPLIER_DELETE_PENDING'
export const SUPPLIER_DELETE_SUCCESS = 'SUPPLIER_DELETE_SUCCESS'
export const SUPPLIER_DELETE_FAILURE = 'SUPPLIER_DELETE_FAILURE'

export const SUPPLIER_TASKS_PENDING = 'SUPPLIER_TASKS_PENDING'
export const SUPPLIER_TASKS_SUCCESS = 'SUPPLIER_TASKS_SUCCESS'
export const SUPPLIER_TASKS_FAILURE = 'SUPPLIER_TASKS_FAILURE'
export const SUPPLIER_TASKS_NO_CONTENT = 'SUPPLIER_TASKS_NO_CONTENT'

export const SUPPLIER_TASKS_COUNT_PENDING = 'SUPPLIER_TASKS_COUNT_PENDING'
export const SUPPLIER_TASKS_COUNT_SUCCESS = 'SUPPLIER_TASKS_COUNT_SUCCESS'
export const SUPPLIER_TASKS_COUNT_FAILURE = 'SUPPLIER_TASKS_COUNT_FAILURE'

export const SUPPLIER_RESET_STORE = 'SUPPLIER_RESET_STORE'
