/**
 * Check if route requires permission to access, if so, check for user role and permission.
 *
 * @param route
 * @param redirect
 */
export default function ({ route, app, store }) {
  const path = store?.state?.app?.storedQuery?.path
  const query = store?.state?.app?.storedQuery?.query
  if (path === route.path && !Object.keys(route.query).length) {
    app.router.replace({ path: route.path, query })
  }
}
