// RISK LEVEL CONSTANTS
export const RISK_CRITERIA_INDEX_PENDING = 'RISK_CRITERIA_INDEX_PENDING'
export const RISK_CRITERIA_INDEX_SUCCESS = 'RISK_CRITERIA_INDEX_SUCCESS'
export const RISK_CRITERIA_INDEX_FAILURE = 'RISK_CRITERIA_INDEX_FAILURE'
export const RISK_CRITERIA_INDEX_NO_CONTENT = 'RISK_CRITERIA_INDEX_NO_CONTENT'

export const RISK_CRITERIA_UPDATE_PENDING = 'RISK_CRITERIA_UPDATE_PENDING'
export const RISK_CRITERIA_UPDATE_SUCCESS = 'RISK_CRITERIA_UPDATE_SUCCESS'
export const RISK_CRITERIA_UPDATE_FAILURE = 'RISK_CRITERIA_UPDATE_FAILURE'

export const RISK_CRITERIA_DELETE_PENDING = 'RISK_CRITERIA_DELETE_PENDING'
export const RISK_CRITERIA_DELETE_SUCCESS = 'RISK_CRITERIA_DELETE_SUCCESS'
export const RISK_CRITERIA_DELETE_FAILURE = 'RISK_CRITERIA_DELETE_FAILURE'

export const RISK_CRITERIA_RESET_STORE = 'RISK_CRITERIA_RESET_STORE'
