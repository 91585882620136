export default () => {
  return [
    'actionPlan',
    'actionPlanTask',
    'approval',
    'approvalComment',
    'archive',
    'archiveFile',
    'archiveFileBookmark',
    'audit',
    'auditor',
    'businessType',
    'company',
    'companyUnit',
    'conversation',
    'cvr',
    'deviation',
    'deviationFile',
    'file',
    'google',
    'jobTitle',
    'log',
    'notification',
    'organization',
    'organizationBilling',
    'organizationGroup',
    'organizationReportType',
    'organizationSubscription',
    'permission',
    'procedureAppendix',
    'procedureTemplate',
    'report',
    'reportProcedure',
    'reportProcedureAppendix',
    'reportProcedureAppendixSection',
    'reportProcedureContent',
    'reportProcedureContentSection',
    'reportSection',
    'reportTemplate',
    'reportType',
    'role',
    'sectionType',
    'snippet',
    'statistics',
    'subscription',
    'suggestion',
    'suggestionFile',
    'table',
    'task',
    'taskComment',
    'taskFile',
    'unit',
    'unitCategory',
    'unitFile',
    'userModelRead',
    'subscriptionOrganization',
    'reportCategory',
    'twoFactor',
    'riskLevel',
    'itTag',
    'itGroup',
    'itGroupItem',
    'itGroupProtection',
    'risk',
    'riskLevel',
    'riskCriteria',
    'riskDefinition',
    'riskTag'
  ]
}
