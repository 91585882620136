import * as C from './constants'
import storeToReset from '@/store/storeToReset'

export default {

  /**
   * @param commit
   * @param $config
   * @param payload
   * @returns {Promise<void>}
   */
  toggleVerifyEmailModal ({ commit }, payload) {
    commit(C.APP_TOGGLE_VERIFY_EMAIL_MODAL, payload)
  },

  /**
   * @param commit
   * @param payload
   */
  toggleSidebar ({ commit }, payload) {
    commit(C.APP_TOGGLE_SIDEBAR, payload)
  },

  /**
   * @param commit
   * @param payload
   */
  collapseSidebar ({ commit }, payload) {
    commit(C.APP_COLLAPSE_SIDEBAR, payload)
  },

  /**
   * @param state
   * @param payload
   */
  toggleSessionExpired ({ commit }, payload) {
    commit(C.APP_TOGGLE_SESSION_EXPIRED, payload)
  },

  /**
   * @param state
   * @param payload
   */
  storeQuery ({ commit }, payload) {
    commit(C.APP_STORE_QUERY, payload)
  },

  /**
   * @param state
   * @param payload
   */
  setFilters ({ commit }, payload) {
    commit(C.APP_SET_FILTERS, payload)
  },

  /**
   * @param commit
   */
  resetAppStore ({ commit }) {
    commit(C.APP_RESET_STORE)
  },

  /**
   * @param dispatch
   */
  resetFullStore ({ dispatch }) {
    storeToReset().forEach((store) => {
      const capitalizedStore = store[0].toUpperCase() + store.slice(1)
      const path = `${store}/reset${capitalizedStore}Store`
      dispatch(path, null, { root: true })
    })
  }
}
