// RISK CONSTANTS
export const RISK_INDEX_PENDING = 'RISK_INDEX_PENDING'
export const RISK_INDEX_SUCCESS = 'RISK_INDEX_SUCCESS'
export const RISK_INDEX_FAILURE = 'RISK_INDEX_FAILURE'
export const RISK_INDEX_NO_CONTENT = 'RISK_INDEX_NO_CONTENT'

export const RISK_SHOW_PENDING = 'RISK_SHOW_PENDING'
export const RISK_SHOW_SUCCESS = 'RISK_SHOW_SUCCESS'
export const RISK_SHOW_FAILURE = 'RISK_SHOW_FAILURE'
export const RISK_SHOW_NO_CONTENT = 'RISK_SHOW_NO_CONTENT'

export const RISK_CREATE_PENDING = 'RISK_CREATE_PENDING'
export const RISK_CREATE_SUCCESS = 'RISK_CREATE_SUCCESS'
export const RISK_CREATE_FAILURE = 'RISK_CREATE_FAILURE'

export const RISK_UPDATE_PENDING = 'RISK_UPDATE_PENDING'
export const RISK_UPDATE_SUCCESS = 'RISK_UPDATE_SUCCESS'
export const RISK_UPDATE_FAILURE = 'RISK_UPDATE_FAILURE'

export const RISK_DELETE_PENDING = 'RISK_DELETE_PENDING'
export const RISK_DELETE_SUCCESS = 'RISK_DELETE_SUCCESS'
export const RISK_DELETE_FAILURE = 'RISK_DELETE_FAILURE'

export const RISK_EXISTS_PENDING = 'RISK_EXISTS_PENDING'
export const RISK_EXISTS_SUCCESS = 'RISK_EXISTS_SUCCESS'
export const RISK_EXISTS_FAILURE = 'RISK_EXISTS_FAILURE'
export const RISK_EXISTS_NO_CONTENT = 'RISK_EXISTS_NO_CONTENT'
export const RISK_EXISTS_RESET = 'RISK_EXISTS_RESET'

export const RISK_RESET_STORE = 'RISK_RESET_STORE'
