import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRiskAssessmentScoreIndex ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_INDEX_PENDING)
      const res = await this.$axios.get('risk-assessment-scores', { params: payload })
      if (res.status === 200) {
        commit(C.RISK_ASSESSMENT_SCORE_INDEX_SUCCESS, res.data)
      } else {
        commit(C.RISK_ASSESSMENT_SCORE_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createRiskAssessmentScore ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_CREATE_PENDING)
      const res = await this.$axios.post('risk-assessment-scores', payload)
      commit(C.RISK_ASSESSMENT_SCORE_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateRiskAssessmentScore ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_UPDATE_PENDING)
      const res = await this.$axios.put(`risk-assessment-scores/${payload.id}`, payload)
      commit(C.RISK_ASSESSMENT_SCORE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async completeRiskAssessmentScore ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_UPDATE_PENDING)
      const res = await this.$axios.put(`risk-assessment-scores/${payload.id}/complete`)
      commit(C.RISK_ASSESSMENT_SCORE_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteRiskAssessmentScore ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_DELETE_PENDING)
      const res = await this.$axios.delete(`risk-assessment-scores/${payload.id}`)
      commit(C.RISK_ASSESSMENT_SCORE_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRiskAssessmentScoreTasks ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_PENDING)
      const res = await this.$axios.get(`risk-assessment-scores/${payload.id}/tasks`)
      if (res.status === 200) {
        commit(C.RISK_ASSESSMENT_SCORE_TASKS_SUCCESS, res.data)
      } else {
        commit(C.RISK_ASSESSMENT_SCORE_TASKS_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createRiskAssessmentScoreTask ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_CREATE_PENDING)
      const res = await this.$axios.post(`risk-assessment-scores/${payload.id}/tasks`, payload)
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createRiskAssessmentScoreVerificationTask ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_CREATE_PENDING)
      const res = await this.$axios.post(`risk-assessment-scores/${payload.id}/tasks/verification`, payload)
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_SCORE_TASKS_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRiskAssessmentScoreStore ({ commit }) {
    commit(C.RISK_ASSESSMENT_SCORE_RESET_STORE)
  }
}
