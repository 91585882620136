// RISK ASSESSMENT SCORE CONSTANTS
export const RISK_ASSESSMENT_SCORE_INDEX_PENDING = 'RISK_ASSESSMENT_SCORE_INDEX_PENDING'
export const RISK_ASSESSMENT_SCORE_INDEX_SUCCESS = 'RISK_ASSESSMENT_SCORE_INDEX_SUCCESS'
export const RISK_ASSESSMENT_SCORE_INDEX_FAILURE = 'RISK_ASSESSMENT_SCORE_INDEX_FAILURE'
export const RISK_ASSESSMENT_SCORE_INDEX_NO_CONTENT = 'RISK_ASSESSMENT_SCORE_INDEX_NO_CONTENT'

export const RISK_ASSESSMENT_SCORE_CREATE_PENDING = 'RISK_ASSESSMENT_SCORE_CREATE_PENDING'
export const RISK_ASSESSMENT_SCORE_CREATE_SUCCESS = 'RISK_ASSESSMENT_SCORE_CREATE_SUCCESS'
export const RISK_ASSESSMENT_SCORE_CREATE_FAILURE = 'RISK_ASSESSMENT_SCORE_CREATE_FAILURE'

export const RISK_ASSESSMENT_SCORE_UPDATE_PENDING = 'RISK_ASSESSMENT_SCORE_UPDATE_PENDING'
export const RISK_ASSESSMENT_SCORE_UPDATE_SUCCESS = 'RISK_ASSESSMENT_SCORE_UPDATE_SUCCESS'
export const RISK_ASSESSMENT_SCORE_UPDATE_FAILURE = 'RISK_ASSESSMENT_SCORE_UPDATE_FAILURE'

export const RISK_ASSESSMENT_SCORE_DELETE_PENDING = 'RISK_ASSESSMENT_SCORE_DELETE_PENDING'
export const RISK_ASSESSMENT_SCORE_DELETE_SUCCESS = 'RISK_ASSESSMENT_SCORE_DELETE_SUCCESS'
export const RISK_ASSESSMENT_SCORE_DELETE_FAILURE = 'RISK_ASSESSMENT_SCORE_DELETE_FAILURE'

export const RISK_ASSESSMENT_SCORE_TASKS_PENDING = 'RISK_ASSESSMENT_SCORE_TASKS_PENDING'
export const RISK_ASSESSMENT_SCORE_TASKS_SUCCESS = 'RISK_ASSESSMENT_SCORE_TASKS_SUCCESS'
export const RISK_ASSESSMENT_SCORE_TASKS_FAILURE = 'RISK_ASSESSMENT_SCORE_TASKS_FAILURE'
export const RISK_ASSESSMENT_SCORE_TASKS_NO_CONTENT = 'RISK_ASSESSMENT_SCORE_TASKS_NO_CONTENT'

export const RISK_ASSESSMENT_SCORE_TASKS_CREATE_PENDING = 'RISK_ASSESSMENT_SCORE_TASKS_CREATE_PENDING'
export const RISK_ASSESSMENT_SCORE_TASKS_CREATE_SUCCESS = 'RISK_ASSESSMENT_SCORE_TASKS_CREATE_SUCCESS'
export const RISK_ASSESSMENT_SCORE_TASKS_CREATE_FAILURE = 'RISK_ASSESSMENT_SCORE_TASKS_CREATE_FAILURE'

export const RISK_ASSESSMENT_SCORE_RESET_STORE = 'RISK_ASSESSMENT_SCORE_RESET_STORE'
