
import { mapActions, mapState } from 'vuex'
import CountryFlag from 'vue-country-flag'
import roleTypes from '@/constants/roleTypes'
import errorHandlingMixin from '@/mixins/errorHandlingMixin.vue'
import onboardingStates from '@/constants/onboardingStates'
import locales from '@/constants/locales'

export default {
  name: 'NowNavAccountMenu',
  components: {
    CountryFlag
  },
  mixins: [errorHandlingMixin],
  data () {
    return {
      selectCompanyUnit: false,
      selectLocale: false
    }
  },
  computed: {
    ...mapState({
      companyUnitIndex: state => state.companyUnit.index.items
    }),
    organization () {
      return this.$auth.user?.organization || null
    },
    organizations () {
      return this.$auth.user?.organizations?.filter(org => org.id !== this.organization?.id) || []
    },
    userOrganizationsCount () {
      return this.$auth.user?.organizations?.length || 0
    },
    companyUnits () {
      // Admins should have access to all company units
      if (this.$auth.user.role === roleTypes.ADMIN) {
        return this.companyUnitIndex?.filter(companyUnit => companyUnit.active)
      } else {
        return this.$auth.user?.company_units?.filter(companyUnit => companyUnit.active)
      }
    },
    selectedCompanyUnit () {
      return this.$auth.user?.company_unit
    },
    canSelectCompanyUnit () {
      return this.companyUnits.length >= 1
    },
    isAdmin () {
      return this.$auth.user?.role === roleTypes.ADMIN
    },
    localeFlag () {
      const i18nLocale = this.$i18n.locale
      return this.getLocaleFromCode(i18nLocale)?.flag
    }
  },
  watch: {
    '$route.path' () {
      this.close()
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    ...mapActions({
      getCompanyUnitIndex: 'companyUnit/getCompanyUnitIndex',
      updateUserCompanyUnit: 'user/updateUserCompanyUnit',
      updateOrganizationOnboarding: 'organization/updateOrganizationOnboarding',
      updateUserOrganization: 'user/updateUserOrganization',
      updateUserLocale: 'user/updateUserLocale',
      resetUnitStore: 'unit/resetUnitStore',
      resetFullStore: 'app/resetFullStore'
    }),
    initialize () {
      this.getCompanyUnitIndex()
    },
    toggleSelectCompanyUnit () {
      if (this.canSelectCompanyUnit) {
        this.selectCompanyUnit = !this.selectCompanyUnit
      } else {
        this.selectCompanyUnit = false
      }
    },
    toggleSelectLocale () {
      this.selectLocale = !this.selectLocale
    },
    async setUserCompanyUnit (companyUnitId) {
      try {
        await this.$toast.show(this.$t('toast.pending'))
        await this.updateUserCompanyUnit({
          company_unit_id: companyUnitId
        })
        await this.resetFullStore()
        await this.$auth.fetchUser()
        await this.getCompanyUnitIndex()
        await this.$toast.success(this.$t('toast.success'))
        this.reset()
      } catch (err) {
        await this.$auth.fetchUser()
        this.displayErrorMessages(err)
      }
    },
    async setUserOrganization (organizationId) {
      try {
        await this.$toast.show(this.$t('toast.pending'))
        await this.updateUserOrganization({
          organization_id: organizationId
        })
        await this.resetFullStore()
        await this.$auth.fetchUser()
        await this.$toast.success(this.$t('toast.success'))
        this.close()
      } catch (err) {
        await this.$auth.fetchUser()
        this.displayErrorMessages(err)
      }
    },
    async handleStartOnboarding () {
      try {
        await this.$toast.show(this.$t('toast.pending'))
        await this.updateOrganizationOnboarding({
          id: this.$auth.user.organization_id,
          onboarding: onboardingStates.PENDING
        })
        await this.$auth.fetchUser()
        await this.$toast.success(this.$t('toast.success'))
        await this.$router.push('/onboarding')
      } catch (err) {
        await this.displayErrorMessages(err)
      }
    },
    async logout () {
      await this.$auth.logout()
    },
    async setLocale (locale) {
      try {
        await this.$toast.show(this.$t('toast.pending'))
        await this.updateUserLocale({ locale })
        await this.$auth.fetchUser()
        await this.$toast.success(this.$t('toast.success'))
        await this.$i18n.setLocale(locale)
        this.selectLocale = false
      } catch (err) {
        await this.displayErrorMessages(err)
      }
    },
    getLocaleFromCode (code) {
      return locales[code]
    },
    reset () {
      this.selectCompanyUnit = false
    },
    close () {
      this.$emit('close')
    }
  }
}
