import * as C from './constants'

export default {

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRiskAssessmentIndex ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_INDEX_PENDING)
      const res = await this.$axios.get('risk-assessments', { params: payload })
      if (res.status === 200) {
        commit(C.RISK_ASSESSMENT_INDEX_SUCCESS, res.data)
      } else {
        commit(C.RISK_ASSESSMENT_INDEX_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_ASSESSMENT_INDEX_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async createRiskAssessment ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_CREATE_PENDING)
      const res = await this.$axios.post('risk-assessments', payload)
      commit(C.RISK_ASSESSMENT_CREATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_CREATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async updateRiskAssessment ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_UPDATE_PENDING)
      const res = await this.$axios.put(`risk-assessments/${payload.id}`, payload)
      commit(C.RISK_ASSESSMENT_UPDATE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_UPDATE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async deleteRiskAssessment ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_DELETE_PENDING)
      const res = await this.$axios.delete(`risk-assessments/${payload.id}`)
      commit(C.RISK_ASSESSMENT_DELETE_SUCCESS, res.data)
    } catch (err) {
      commit(C.RISK_ASSESSMENT_DELETE_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   * @param payload
   * @returns {Promise<void>}
   */
  async getRiskAssessmentHistory ({ commit }, payload) {
    try {
      commit(C.RISK_ASSESSMENT_HISTORY_PENDING)
      const res = await this.$axios.get(`risk-assessments/${payload.id}/history`)
      if (res.status === 200) {
        commit(C.RISK_ASSESSMENT_HISTORY_SUCCESS, res.data)
      } else {
        commit(C.RISK_ASSESSMENT_HISTORY_NO_CONTENT)
      }
    } catch (err) {
      commit(C.RISK_ASSESSMENT_HISTORY_FAILURE, err.response.data)
      throw err
    }
  },

  /**
   * @param commit
   */
  resetRiskAssessmentStore ({ commit }) {
    commit(C.RISK_ASSESSMENT_RESET_STORE)
  }
}
