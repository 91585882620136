
import { mapState } from 'vuex'
import breakpoints from '@/constants/breakpoints'
import resizeMixin from '@/mixins/resizeMixin.vue'

export default {
  name: 'NowOrganizationSidebar',
  mixins: [resizeMixin],
  props: {
    toggled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      links: [
        { label: 'data', to: '/organization', icon: 'user-circle' },
        { label: 'companies', to: '/organization/companies', icon: 'building-office' },
        { label: 'members', to: '/organization/members', icon: 'users' },
        { label: 'groups', to: '/organization/groups', icon: 'building-storefront' },
        { label: 'job', to: '/organization/job', icon: 'briefcase' },
        { label: 'subscription', to: '/organization/subscription', icon: 'globe-alt' },
        { label: 'billing', to: '/organization/billing', icon: 'credit-card' },
        { label: 'events', to: '/organization/events', icon: 'clipboard-document-list' }
      ],
      breakpoints
    }
  },
  computed: {
    ...mapState({
      organizationGroupIndex: state => state.organizationGroup.index.items,
      organizationGroupIndexLoading: state => state.organizationGroup.index.loading
    }),
    isMobile () {
      return this.window.w < breakpoints.MD
    },
    organizationName () {
      return this.$auth?.user?.organization?.name
    }
  },
  watch: {
    '$route.path' () {
      this.$emit('toggle', false)
    },
    '$route.params' () {
      this.$emit('toggle', false)
    },
    isMobile () {
      this.$emit('toggle', false)
    }
  },
  methods: {
    toggle () {
      this.$emit('toggle')
    },
    isActive (link) {
      return link.to === this.$route.path
    }
  }
}
