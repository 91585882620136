import { set } from 'vue'
import * as C from './constants'
import * as initState from './state'

export default {

  /**
   * @param state
   */
  [C.SUPPLIER_INDEX_PENDING] (state) {
    set(state, 'index', {
      ...state.index,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_INDEX_SUCCESS] (state, payload) {
    set(state, 'index', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_INDEX_FAILURE] (state, payload) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_INDEX_NO_CONTENT] (state) {
    set(state, 'index', {
      item: null,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_LIST_PENDING] (state) {
    set(state, 'list', {
      items: [],
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_LIST_SUCCESS] (state, payload) {
    set(state, 'list', {
      items: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_LIST_FAILURE] (state, payload) {
    set(state, 'list', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_LIST_NO_CONTENT] (state, payload) {
    set(state, 'list', {
      items: [],
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_SHOW_PENDING] (state) {
    set(state, 'show', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_SHOW_SUCCESS] (state, payload) {
    set(state, 'show', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_SHOW_FAILURE] (state, payload) {
    set(state, 'show', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_CREATE_PENDING] (state) {
    set(state, 'create', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_CREATE_SUCCESS] (state, payload) {
    set(state, 'create', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_CREATE_FAILURE] (state, payload) {
    set(state, 'create', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_UPDATE_PENDING] (state) {
    set(state, 'update', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_UPDATE_SUCCESS] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_UPDATE_FAILURE] (state, payload) {
    set(state, 'update', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_DELETE_PENDING] (state) {
    set(state, 'delete', {
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_DELETE_SUCCESS] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_DELETE_FAILURE] (state, payload) {
    set(state, 'delete', {
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_TASKS_PENDING] (state) {
    set(state, 'tasks', {
      item: null,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_TASKS_SUCCESS] (state, payload) {
    set(state, 'tasks', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_TASKS_FAILURE] (state, payload) {
    set(state, 'tasks', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_TASKS_NO_CONTENT] (state, payload) {
    set(state, 'tasks', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_TASKS_COUNT_PENDING] (state) {
    set(state, 'tasksCount', {
      ...state.tasksCount,
      loading: true,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_TASKS_COUNT_SUCCESS] (state, payload) {
    set(state, 'tasksCount', {
      item: payload,
      loading: false,
      error: null
    })
  },

  /**
   * @param state
   * @param payload
   */
  [C.SUPPLIER_TASKS_COUNT_FAILURE] (state, payload) {
    set(state, 'tasksCount', {
      item: null,
      loading: false,
      error: payload
    })
  },

  /**
   * @param state
   */
  [C.SUPPLIER_RESET_STORE] (state) {
    Object.assign(state, initState.default())
  }
}
