// UNIT CONSTANTS
export const CONTACT_INDEX_PENDING = 'CONTACT_INDEX_PENDING'
export const CONTACT_INDEX_SUCCESS = 'CONTACT_INDEX_SUCCESS'
export const CONTACT_INDEX_FAILURE = 'CONTACT_INDEX_FAILURE'
export const CONTACT_INDEX_NO_CONTENT = 'CONTACT_INDEX_NO_CONTENT'

export const CONTACT_SHOW_PENDING = 'CONTACT_SHOW_PENDING'
export const CONTACT_SHOW_SUCCESS = 'CONTACT_SHOW_SUCCESS'
export const CONTACT_SHOW_FAILURE = 'CONTACT_SHOW_FAILURE'

export const CONTACT_CREATE_PENDING = 'CONTACT_CREATE_PENDING'
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS'
export const CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE'

export const CONTACT_UPDATE_PENDING = 'CONTACT_UPDATE_PENDING'
export const CONTACT_UPDATE_SUCCESS = 'CONTACT_UPDATE_SUCCESS'
export const CONTACT_UPDATE_FAILURE = 'CONTACT_UPDATE_FAILURE'

export const CONTACT_DELETE_PENDING = 'CONTACT_DELETE_PENDING'
export const CONTACT_DELETE_SUCCESS = 'CONTACT_DELETE_SUCCESS'
export const CONTACT_DELETE_FAILURE = 'CONTACT_DELETE_FAILURE'

export const CONTACT_RESET_STORE = 'CONTACT_RESET_STORE'
